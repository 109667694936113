@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('@radix-ui/colors/tomato-dark.css');
@import url('@radix-ui/colors/mauve-dark.css');
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('InfiniteBeyondItalic', '../fonts/InfiniteBeyondItalic');
@include font('FFFUrban', '../fonts/FFFUrban');

.purpleStroke {
  -webkit-text-stroke: 1px #9747ff;
}

html {
  -webkit-text-size-adjust: 100%;
}

.spaceFont {
  font-family: InfiniteBeyondItalic, monospace;
  letter-spacing: 0.1em;
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.pixelFont {
  font-family: 'FFFUrban', monospace;
  text-shadow: 0 0 4px black;
}

:root {
  color-scheme: dark;
  --primary: rgb(24 24 27);
  --alt: #2d2d2d;
  --background: black;
  --textColor: white;
  --altTextColor: black;
  --boxShadowBottomRight: #070809;
  --color-error: #dc2626;
}

* {
  box-sizing: border-box;
  font-family: 'FFFUrban', monospace;
  letter-spacing: 0.01em;
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  overscroll-behavior: none;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rainbowText {
  -webkit-animation: color-change 3s infinite;
  animation: color-change 3s infinite;
}
@keyframes color-change {
  0% {
    color: red;
  }

  25% {
    color: pink;
  }

  50% {
    color: blue;
  }

  75% {
    color: yellow;
  }

  100% {
    color: green;
  }
}

.blurred {
  filter: blur(12px);
  opacity: 0.5;
}

.twitter {
  text-decoration: none;
  animation: twitter-colors 2s infinite alternate;
}

.discord {
  text-decoration: none;
  animation: discord-colors 2s infinite;
}

.tiktok {
  text-decoration: none;
  animation: tiktok-colors 2s infinite;
}

.youtube {
  text-decoration: none;
  animation: youtube-colors 2s infinite;
}

.instagram {
  text-decoration: none;
  animation: instagram-colors 2s infinite;
}

.twitterNoShadow {
  animation: twitter-colors-noShadow 3s infinite;
}

.telegram {
  text-decoration: none;
  animation: telegram-colors 2s infinite;
}

.discordNoShadow {
  animation: discord-colors-noShadow 3s infinite;
}

@keyframes twitter-colors-noShadow {
  0% {
    fill: #0077ff;
    color: #0077ff;
  }

  50% {
    fill: #00e1ff;
    color: #00e1ff;
  }

  100% {
    fill: #3867ff;
    color: #3867ff;
  }
}

@keyframes twitter-colors {
  0% {
    fill: #0077ff;
    color: #0077ff;
    text-shadow: 0 0 0.15em #0077ff;
  }

  50% {
    fill: #00e1ff;
    color: #00e1ff;
    text-shadow: 0 0 0.15em #3867ff;
  }

  100% {
    fill: #3867ff;
    color: #3867ff;
    text-shadow: 0 0 0.15em #00e1ff;
  }
}
@keyframes twitter-colors-icon {
  0% {
    filter: invert(26%) sepia(82%) saturate(2598%) hue-rotate(203deg) brightness(105%) contrast(103%);
  }

  50% {
    filter: invert(32%) sepia(53%) saturate(2451%) hue-rotate(215deg) brightness(99%) contrast(105%);
  }

  100% {
    filter: invert(80%) sepia(38%) saturate(6178%) hue-rotate(145deg) brightness(104%) contrast(107%);
  }
}

@keyframes discord-colors {
  0% {
    fill: #7e41a4;
    color: #7e41a4;
    text-shadow: 0 0 0.15em #7e41a4;
  }

  50% {
    fill: #c483ed;
    color: #c483ed;
    text-shadow: 0 0 0.15em #c483ed;
  }

  100% {
    fill: #703396;
    color: #703396;
    text-shadow: 0 0 0.15em #703396;
  }
}
@keyframes discord-colors-noShadow {
  0% {
    fill: #7e41a4;
    color: #7e41a4;
  }

  50% {
    fill: #c483ed;
    color: #c483ed;
  }

  100% {
    fill: #703396;
    color: #703396;
  }
}

@keyframes discord-colors-icon {
  0% {
    filter: invert(30%) sepia(21%) saturate(3173%) hue-rotate(245deg) brightness(93%) contrast(89%);
  }

  50% {
    filter: invert(74%) sepia(52%) saturate(4336%) hue-rotate(221deg) brightness(97%) contrast(91%);
  }

  100% {
    filter: invert(26%) sepia(26%) saturate(3387%) hue-rotate(251deg) brightness(90%) contrast(95%);
  }
}

@keyframes telegram-colors {
  0% {
    fill: #0088cc;
    color: #0088cc;
    text-shadow: 0 0 0.15em #0088cc;
  }

  50% {
    fill: #00a9e0;
    color: #00a9e0;
    text-shadow: 0 0 0.15em #00a9e0;
  }

  100% {
    fill: #0088cc;
    color: #0088cc;
    text-shadow: 0 0 0.15em #0088cc;
  }
}

@keyframes tiktok-colors {
  0% {
    fill: #8af34d;
    color: #8af34d;
    text-shadow: 0 0 0.15em #000000;
  }

  50% {
    fill: #d1d1d1;
    color: #d1d1d1;
    text-shadow: 0 0 0.15em #69c9d0;
  }

  100% {
    fill: #69c9d0;
    color: #69c9d0;
    text-shadow: 0 0 0.15em #ffffff;
  }
}

@keyframes youtube-colors {
  0% {
    fill: #ff4242;
    color: #ff4242;
    text-shadow: 0 0 0.15em #000000;
  }

  50% {
    fill: #ff6161;
    color: #ff6161;
    text-shadow: 0 0 0.15em #ff0000;
  }

  100% {
    fill: #bb3535;
    color: #bb3535;
    text-shadow: 0 0 0.15em #ffffff;
  }
}

@keyframes instagram-colors {
  0% {
    fill: #ff0000;
    color: #ff0000;
    text-shadow: 0 0 0.15em #000000;
  }

  50% {
    fill: #fc6ced;
    color: #fc6ced;
    text-shadow: 0 0 0.15em #ff0000;
  }

  100% {
    fill: #ffb030;
    color: #ffb030;
    text-shadow: 0 0 0.15em #ffffff;
  }
}

.buyText {
  animation: rainbow-colors 3s infinite;
}

@keyframes rainbow-colors {
  0% {
    fill: #1aff00;
    color: #1aff00;
    text-shadow: 0 0 0.15em #1aff00;
  }

  25% {
    fill: #d9ff00;
    color: #d9ff00;
    text-shadow: 0 0 0.15em #d9ff00;
  }

  50% {
    fill: #ff00bb;
    color: #ff00bb;
    text-shadow: 0 0 0.15em #ff00bb;
  }

  100% {
    fill: #00e1ff;
    color: #00e1ff;
    text-shadow: 0 0 0.15em #00e1ff;
  }
}

/* start of glitchAndJerk animation */
.glitchAndJerk {
  animation: glitch 3s alternate infinite;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, -2px) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(2deg);
  }
}

.glitchAndJerk:before,
.glitchAndJerk:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitchAndJerk:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.glitchAndJerk:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}

/* Add to style.css */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: 100vh;
    max-height: -webkit-fill-available !important;
  }
}

.health-bar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 250px;
  height: 40px;
  padding: 5px;
  background: #ddd;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  font-size: 10px;
  color: white;
}
.bar {
  background: #c54;
  width: 100%;
  height: 30px;
  position: relative;

  transition: width 0.6s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.hit {
  background: rgba(223, 87, 87, 0.6);
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  right: 5px;

  transition: width 0.5s linear;
}

.spinner {
  animation: spin 2s linear infinite;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.slide-in {
  transform: translateX(0);
  transition: transform 0.1s ease-in;
}
@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.slide-in-2 {
  animation: slideIn 0.2s ease-in;
}

.slide-out {
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.wiggle {
  animation: wiggle 1s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

img {
  max-width: 500%;
}

.container {
  transition: width 1s ease-in-out;
}

// undo the tailwindcss reset
h1 {
  font-size: revert;
}
h2 {
  font-size: revert;
}
h3 {
  font-size: revert;
}
h4 {
  font-size: revert;
}
h5 {
  font-size: revert;
}
h6 {
  font-size: revert;
}
ul {
  list-style-type: disc;
  padding: revert;
}
ol {
  list-style-type: decimal;
  padding: revert;
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

img {
  max-width: 90vw;
}

/* Add some glitch effect and blur animations */
.blur-effect {
  filter: blur(2px);
  transition:
    filter 0.5s ease,
    transform 0.3s ease;
}

.blur-effect:hover {
  filter: blur(1px);
  transform: scale(1.05);
}

/* Glitch overlay animation */
.glitch-overlay {
  animation: glitch 2s infinite;
  transition: opacity 0.3s ease;
}

/* Keyframes for glitch effect */
@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(3px, -3px);
  }
  40% {
    transform: translate(-3px, 3px);
  }
  60% {
    transform: translate(2px, -2px);
  }
  80% {
    transform: translate(-2px, 2px);
  }
  100% {
    transform: translate(0);
  }
}

.dark-editor {
  --spacing-2: 1rem;

  --accentBase: var(--tomato-1);
  --accentBgSubtle: var(--tomato-2);
  --accentBg: var(--tomato-3);
  --accentBgHover: var(--tomato-4);
  --accentBgActive: var(--tomato-5);
  --accentLine: var(--tomato-6);
  --accentBorder: var(--tomato-7);
  --accentBorderHover: var(--tomato-8);
  --accentSolid: var(--tomato-9);
  --accentSolidHover: var(--tomato-10);
  --accentText: var(--tomato-11);
  --accentTextContrast: var(--tomato-12);

  --baseBase: var(--mauve-1);
  --baseBgSubtle: var(--mauve-2);
  --baseBg: var(--mauve-3);
  --baseBgHover: var(--mauve-4);
  --baseBgActive: var(--mauve-5);
  --baseLine: var(--mauve-6);
  --baseBorder: var(--mauve-7);
  --baseBorderHover: var(--mauve-8);
  --baseSolid: var(--mauve-9);
  --baseSolidHover: var(--mauve-10);
  --baseText: var(--mauve-12);
  --baseTextContrast: var(--mauve-12);

  --admonitionTipBg: var(--cyan4);
  --admonitionTipBorder: var(--cyan8);

  --admonitionInfoBg: var(--grass4);
  --admonitionInfoBorder: var(--grass8);

  --admonitionCautionBg: var(--amber4);
  --admonitionCautionBorder: var(--amber8);

  --admonitionDangerBg: var(--red4);
  --admonitionDangerBorder: var(--red8);

  --admonitionNoteBg: var(--mauve-4);
  --admonitionNoteBorder: var(--mauve-8);

  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

  color: var(--baseText);
  --basePageBg: black;
  background: var(--basePageBg);
}

.mdxeditor {
  height: 100%;
  overflow-y: auto;
  border: none;
  border-radius: 15px;
}
.mdxeditor-root-contenteditable {
  overflow-y: auto;
  padding: 5px;

  p {
    border-top: 0.1px dotted var(--baseBorder);
  }
}

._toolbarGroupOfGroups_uazmk_217 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.toolbar {
}

._toolbarButton_uazmk_237 {
}

._toolbarButton_uazmk_237:hover {
  color: #787878;
}

._tooltipTrigger_uazmk_676:hover {
}

._toolbarToggleSingleGroup_uazmk_222 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px solid var(--baseBorder);
  background: var(--baseBg);
  gap: 10px;
}
._tooltipTrigger_uazmk_676:hover {
  color: #787878;
}

.mdxeditor-popup-container {
  position: absolute;
  z-index: 1000;
  background: var(--baseBg);
  border: 1px solid var(--baseBorder);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 300px;

  //center the popup
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// has child _dialogContent_uazmk_602
.mdxeditor-popup-container:not(:has(._dialogContent_uazmk_602)) {
  display: none;
}

._multiFieldForm_uazmk_1194 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
._formField_uazmk_1200 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
